<template>
  <v-container>
    <row-header action="back" :msg="msgRowHeader"></row-header>

    <v-row dense>
      <v-col>
        <v-form
            ref="form"
            v-model="valid"
        >
          <v-card>
            <deposit-form-component :deposit_data="deposit_data" update="0" />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  :disabled="!valid"
                  color="success"
                  @click="saveData"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>

    <v-overlay
        :absolute="absolute"
        :value="overlay"
    >
      <v-card color="white">
        <v-card-text>
          <overlay-img></overlay-img>
        </v-card-text>
      </v-card>
    </v-overlay>

  </v-container>
</template>

<script>
import RowHeader from "../../../components/content/RowHeader";
import OverlayImg from "../../../components/content/OverlayImg";
import DepositFormComponent from '../../../components/page/trx/DepositFormComponent';
import { DepositData } from '../../../store/services/TrxModel';
import { PostRequest } from "../../../store/services/users";

export default {
  components: {
    RowHeader,
    OverlayImg,
    DepositFormComponent,
  },
  data:() => ({
    msgRowHeader: {newUser: 'Back', newMsg: 'Create Deposit'},
    valid: true,
    absolute: false,
    overlay: false,
    deposit_data: new DepositData(),
  }),
  mounted() {
    console.log('deposit new page');
    this.deposit_data.active = 1;
  },
  methods: {
    async saveData() {
      await this.$store.dispatch('req_data')
      this.overlay = this.$store.getters.isLoading;
      this.deposit_data.amount = Number(this.deposit_data.amount);
      try {
        await PostRequest('transactions', this.deposit_data);
        this.overlay = this.$store.getters.isLoading;
        await this.$store.dispatch('req_success');
        this.overlay = this.$store.getters.isLoading;
        alert('Create data success');
        await this.$router.push({name: 'DepositList'});
      } catch (e) {
        await this.$store.dispatch('req_failed');
        this.overlay = this.$store.getters.isLoading;
        alert('Create data failed ' + e.message);
      }
    }
  }
}
</script>

<style scoped>

</style>
